import React from 'react';

import appSettings from '../../config/settings';
import { FormattedMessage } from '../../util/reactIntl';

import { LayoutSingleColumn } from '../../components';
import css from './MaintenanceMode.module.css';

// MaintenanceMode component is shown when mandatory app-wide configurations are not found from assets.
// Note 1: this microcopy/translation does not come from translation file.
//         It needs to be something that is not part of fetched assets but built-in text
// Note 2: In the LandingPage directory, there's a similar content (FallbackPage.js).
const MaintenanceMode = props => {
  const isDev = appSettings.dev;
  return (
    <LayoutSingleColumn
      mainColumnClassName={css.layoutWrapperMain}
      topbar={<div className={css.header} title="Maintenance mode"></div>}
      footer={null}
    >
      <section id="content" className={css.root}>
        <div className={css.content}>
          <h2>
            <FormattedMessage id="MaintenanceMode.title" defaultMessage="Could not load site 🙁" />
          </h2>
          
            <>
              <strong>
                If you are using a network filter, please make sure you have the following sites open:
              </strong>
              <ul>
                <li style={{ marginBottom: '10px', color: 'red' }}>sharetribe.imgix.net</li>
                <li style={{ marginBottom: '10px', color: 'red' }}>sharetribe-assets.imgix.net</li>
                <li style={{ marginBottom: '10px', color: 'red' }}>joodaic.com</li>
                <li style={{ marginBottom: '10px', color: 'red' }}>cdn.st-api.com</li>
              </ul>
              <p>
                You can also reach out to us at <a href="mailto:admin@joodaic.com">admin@joodaic.com</a>.
              </p>
            </>
        </div>
      </section>
    </LayoutSingleColumn>
  );
};

export default MaintenanceMode;
