import React from 'react';
import loadable from '@loadable/component';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import BlockContainer from '../PageBuilder/BlockBuilder/BlockContainer';
import Field from '../PageBuilder/Field';
import carouselCSS from '../PageBuilder/SectionBuilder/SectionCarousel/SectionCarousel.module.css';

import FallbackPage from './FallbackPage';

// Import custom components and styles
import css from './ArtistProfile.module.css';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

// Custom Artist Profile Block Component
const ArtistProfileBlock = props => {
  const { 
    blockId, 
    media, 
    title, 
    text, 
    responsiveImageSizes, 
    options
  } = props;

  return (
    <BlockContainer id={blockId} className={carouselCSS.block}>
      <div className={css.artistCard}>
        <div className={css.artworkCollage}>
          <Field data={media} sizes={responsiveImageSizes} options={options} />
        </div>
        <div className={css.artistInfo}>
          {text?.content && (
            <div className={css.profileImage}>
              <img src={text.content} alt="" />
            </div>
          )}
          <div className={css.text}>
            <Field data={title} options={options} className={css.artistName} />
          </div>
        </div>
      </div>
    </BlockContainer>
  );
};

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  // Transform the data to use custom block type for featured section
  const transformedData = pageAssetsData?.landingPage?.data
    ? {
        ...pageAssetsData.landingPage.data,
        sections: pageAssetsData.landingPage.data.sections.map(section => {
          // Ensure all sections have the correct type
          const sectionWithType = {
            ...section,
            sectionType: section.sectionType || 'carousel'
          };
          // Check if this is the Welcome section
          const isWelcomeSection = 
            section?.sectionName?.toLowerCase() == 'welcome';

          if (isWelcomeSection) {
            return {
              ...sectionWithType,
              sectionType: 'video'
            };
          }

          const isFeaturedSection = 
            section?.title?.content?.toLowerCase().includes('featured') ||
            section?.title?.content?.toLowerCase().includes('artist');

          if (isFeaturedSection) {
            return {
              ...sectionWithType,
              numColumns: 4,
              blocks: section.blocks.map(block => ({
                ...block,
                blockType: 'artistBlock'
              }))
            };
          }
          return sectionWithType;
        })
      }
    : null;

  // Define custom components and styles through options
  const options = {
    blockComponents: {
      artistBlock: {
        component: ArtistProfileBlock
      }
    },
    sectionComponents: {
      video: {
        component: loadable(() =>
          import(/* webpackChunkName: "SectionVideo" */ '../PageBuilder/SectionBuilder/SectionVideo/SectionVideo')
        )
      }
    }
  };

  return (
    <PageBuilder
      pageAssetsData={transformedData}
      inProgress={inProgress}
      error={error}
      options={options}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
